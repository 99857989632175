<template>
  <div>
  <v-row :key="forceReload" no-gutters style="border-bottom: 1px solid #CCCCCC80">
    <v-col
      cols="12"
      md="6"
      style="padding:10px"
    >
        <b>
          {{ $t('booking.attendance.id', locale) }}
        </b>
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <b>
        {{ $t('booking.name', locale) }}
      </b>
    </v-col>
  </v-row>
  <draggable
    v-model="items"
    :draggable="'.itemDraggable'"
    style="width:100%;"
    v-bind="dragOptions"
    @start="handleSortStart"
    @end="handleSortItems(items)"
  >
    <v-row 
      v-for="(item, index) in items" 
      :key="item.id" 
      v-bind:class="'itemDraggable'"
      style="padding: 0;margin: 0;cursor: pointer;"
    >
      <v-col
        v-if="selectedIndex !== index"
        cols="12"
        md="6"
        style="padding-left: 0;"
      >
        <v-icon
          small
          class="page__grab-icon"
          style="margin-right: 10px;"
        >
          mdi-cursor-move
        </v-icon>
        <a @click="handleEdit(index)">{{ item.id}}</a>
      </v-col>
      <v-col
        v-else
        cols="12"
        md="5"
        style="color: red;"
      >
          <v-text-field
            label="ID"
            outlined
            hide-details
            v-model="id"
          />
      </v-col>
      <v-col
        v-if="selectedIndex !== index"
        cols="12"
        md="6"
      >
        {{getTranslation(item.name, locale)}}
      </v-col>
      <v-col
        v-else
        cols="12"
        md="5"
      >
        <translate
          v-model="name"
          :languages="languages"
          :label="$t('booking.name', locale)"
          type="text"
        />
      </v-col>
      <v-col
        v-if="selectedIndex === index"
        cols="12"
        md="1"
      >
        <v-icon
          @click="handleSave"
          color="primary"
        >
          mdi-content-save
        </v-icon>
        <v-icon
          @click="handleDelete"
          color="red"
        >
          mdi-delete
        </v-icon>
      </v-col>
    </v-row>
  </draggable>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-btn block :elevation="0" color="primary" @click="handleAdd">
          {{ $t('common.add', locale) }} value
        </v-btn>
      </v-col>
    </v-row>
</div>
</template>
<script>
import Translate from '@/components/Translate'
import draggable from 'vuedraggable'
import utils from '@/services/utils'
export default {
  name: 'List',
  components: {
    Translate,
    draggable,
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    forceReload: {
      type: String,
      default: null,
    },
    languages: {
      type: Array,
      required: true,
    },
    onChange: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    items: [],
    id: null,
    name: null,
    editedItem: null,
    selectedIndex: null,
  }),
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    }
  },
  watch: {
    forceReload () {
      this.prepareData()
    },
    data () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.items = this.data ? JSON.parse(JSON.stringify(this.data)) : []
    },
    getTranslation(v, locale) {
      if (!v) return v
      return utils.getTranslation(v, locale)
    },
    handleAdd () {
      this.id = `new${this.items.length+1}`
      this.name = { es: `new${this.items.length+1}` }
      this.items.push({
        id: this.id,
        name: this.name
      })
      this.selectedIndex = this.items.length -1
    },
    handleEdit (v) {
      this.id = this.items[v].id
      this.name = this.items[v].name
      this.selectedIndex = v
    },
    handleSave () {
      this.items[this.selectedIndex].id = this.id
      this.items[this.selectedIndex].name = this.name
      this.selectedIndex = null
      this.handleChange()
    },
    handleDelete () {
      this.items.splice(this.selectedIndex, 1)
      this.selectedIndex = null
      this.handleChange()
    },
    handleChange () {
      this.onChange(this.items)
    },
    handleSortItems () {
      this.handleChange()
      /*this.data = v
      if (this.data && this.data.length)
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].ORD = i
        }*/
    },
    handleSortStart () {
    },
  }
}
</script>

